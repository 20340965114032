import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-analytics-adapter';
import { bookingsContactInfoSaveSuccess } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  mapFlowTypeToBiProperties,
  mapStateToProperties,
} from '../../../../utils/bi/mappers';
import { isBookingsEnabled } from '../../../../utils/serviceUtils/serviceUtils';
import { Service } from '@wix/bookings-uou-types';
import {
  deprecatedGetDynamicPricingInfo,
  getDynamicPricingInfo,
} from '../../../../utils/dynamicPricing/dynamicPricing';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { isSlotNoBookFlow } from '../../../../utils/slotAvailability/slotAvailability';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { navigateToBookingsServicePage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import { Preference } from '../../../../types/types';
import { DO_NOT_CARE_STAFF } from '../../../../constants/constants';

export type NavigateToNextPage = ({
  selectedSlots,
  isWaitingListFlow,
}: {
  selectedSlots: SlotAvailability[];
  isWaitingListFlow: boolean;
}) => void;

export function createNavigateToNextPageAction(
  actionFactoryParams: ActionFactoryParams<CalendarState, CalendarContext>,
): NavigateToNextPage {
  return async ({ selectedSlots, isWaitingListFlow }) => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const {
      wixSdkAdapter,
      biLogger,
      activeFeatures,
      preset,
      experiments,
      flowAPI,
    } = context;
    const {
      servicesInView,
      selectedTime,
      rescheduleBookingDetails,
      selectedBookingPreferences,
    } = state;

    const selectedSlot = selectedSlots[0];
    const selectedStaffBookingPreference = selectedBookingPreferences?.find(
      (bookingPreference) => bookingPreference.key === Preference.STAFF_MEMBER,
    );
    if (selectedStaffBookingPreference?.value === DO_NOT_CARE_STAFF) {
      delete selectedSlot.slot?.resource;
    }
    const selectedServicesIds =
      selectedSlot.nestedSlots!.length > 1
        ? selectedSlot.nestedSlots!.map((slot) => slot.serviceId!)
        : [selectedSlot.slot?.serviceId!];
    const selectedServices = servicesInView.filter((service) =>
      selectedServicesIds?.includes(service.id),
    )!;

    const isTemplateMode = wixSdkAdapter.isTemplateMode();

    const isBookingEnabled = isBookingsEnabled({
      servicesTypes: selectedServices.map((service) => service.info.type),
      activeFeatures: activeFeatures!,
      isTemplateMode,
    });
    const siteIsEcom =
      !experiments.enabled('specs.bookings.V1Shutdown') ||
      (await context.calendarApi.isEcomSite());
    if (isBookingEnabled && siteIsEcom) {
      const isNoBookFlow = isSlotNoBookFlow(selectedSlot);
      const isReschedulingFlow = !!rescheduleBookingDetails;
      const isBookingFormInstalled =
        await wixSdkAdapter.isBookingFormInstalled();

      void biLogger.report(
        bookingsContactInfoSaveSuccess({
          selectedSlot: selectedTime,
          ...mapFlowTypeToBiProperties({
            isWaitingList: isWaitingListFlow,
            isBookingFormInstalled,
            isNoBookFlow,
            properties: mapStateToProperties({ state, context }),
          }),
        }),
      );

      if (isNoBookFlow) {
        handleNoBookFlow({
          selectedService: selectedServices[0],
          context,
        });
      } else if (isReschedulingFlow) {
        handleReschedulingFlow(actionFactoryParams);
      } else if (isWaitingListFlow) {
        handleWaitingListFlow(actionFactoryParams);
      } else {
        await handleRegularBookFlow(
          actionFactoryParams,
          selectedSlot,
          selectedServices,
        );
      }
    } else {
      if (
        (experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
          ? flowAPI.environment.isPreview
          : wixSdkAdapter.isPreviewMode()) &&
        !isBookingEnabled
      ) {
        // only one service is supported in preview mode
        const serviceType = selectedServices[0].info.type;
        await wixSdkAdapter.openPreviewPremiumModal(serviceType, preset);
      }

      if (
        experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
          ? flowAPI.environment.isViewer
          : wixSdkAdapter.isSiteMode()
      ) {
        setState({
          dialog: {
            type: siteIsEcom
              ? DialogType.PremiumViewer
              : DialogType.siteIsNotEcom,
            state: DialogState.IDLE,
          },
        });
      }
    }
  };
}

const handleWaitingListFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.JoinWaitlist,
      state: DialogState.IDLE,
    },
  });
};

const handleReschedulingFlow = ({
  getControllerState,
}: ActionFactoryParams<CalendarState, CalendarContext>) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.RescheduleConfirm,
      state: DialogState.IDLE,
    },
  });
};

const handleNoBookFlow = ({
  selectedService,
  context,
}: {
  selectedService: Service;
  context: CalendarContext;
}) => {
  const {
    preset: referral,
    wixSdkAdapter,
    experiments,
    flowAPI: {
      controllerConfig: { wixCodeApi },
    },
  } = context;
  const serviceSlug = selectedService!.info.slugs[0];
  if (experiments.enabled('specs.bookings.navigationFromNewModule')) {
    navigateToBookingsServicePage(wixCodeApi, {
      serviceSlug,
      referral,
    });
  } else {
    void wixSdkAdapter.navigateToBookingsServicePage(serviceSlug, {
      referral,
    });
  }
};

const handleRegularBookFlow = async (
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  slotAvailability: SlotAvailability,
  selectedServices: Service[],
) => {
  const {
    wixSdkAdapter,
    businessInfo,
    experiments,
    preset,
    isCartEnabled,
    calendarSelections,
  } = context;
  const [state, setState] = getControllerState();
  const { selectedTimezone, selectedVariantsOptions } = state;

  const isAnywherePublicDataOverridesEnabled =
    experiments &&
    experiments.enabled('specs.bookings.anywherePublicDataOverrides');

  const isMultiServiceAppointmentEnabled = experiments.enabled(
    'specs.bookings.multiServiceAppointment',
  );

  const isMultiServiceAppointment =
    isMultiServiceAppointmentEnabled && slotAvailability.nestedSlots?.length;

  const serviceSlug = selectedServices[0]!.info.slugs[0];

  const continueShoppingUrl =
    isCartEnabled && !isMultiServiceAppointment
      ? `/${wixSdkAdapter.getCurrentPath().join('/')}?${
          BookingsQueryParams.TIMEZONE
        }=${state.selectedTimezone}`
      : undefined;

  const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
    services: selectedServices,
    businessName: businessInfo?.name || '',
  });
  wixSdkAdapter.trackAnalytics(trackingInfo);

  const dynamicPriceInfo = isMultiServiceAppointmentEnabled
    ? getDynamicPricingInfo({
        selectedSlot: slotAvailability,
        servicesSelections: calendarSelections?.services?.length
          ? calendarSelections.services
          : [{ id: selectedServices[0].id, variants: selectedVariantsOptions }],
      })
    : deprecatedGetDynamicPricingInfo(selectedVariantsOptions);

  setState({ calendarStatus: CalendarStatus.NAVIGATING }, { immediate: true });
  if (experiments.enabled('specs.bookings.navigationFromNewModule')) {
    void navigateToBookingsFormPage({
      wixCodeApi: context.flowAPI.controllerConfig.wixCodeApi,
      platformAPIs: context.flowAPI.controllerConfig.platformAPIs,
      slotAvailability,
      serviceSlug,
      dynamicPriceInfo,
      isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
      queryParams: {
        [BookingsQueryParams.REFERRAL]: preset,
        [BookingsQueryParams.TIMEZONE]: selectedTimezone!,
        ...(isCartEnabled
          ? { [BookingsQueryParams.CONTINUE_SHOPPING_URL]: continueShoppingUrl }
          : {}),
      },
    });
  } else {
    void wixSdkAdapter.navigateToBookingsFormPage({
      slotAvailability,
      serviceSlug,
      dynamicPriceInfo,
      isAnywhereSpecEnable: isAnywherePublicDataOverridesEnabled,
      queryParams: {
        [BookingsQueryParams.REFERRAL]: preset,
        [BookingsQueryParams.TIMEZONE]: selectedTimezone!,
        ...(isCartEnabled
          ? { [BookingsQueryParams.CONTINUE_SHOPPING_URL]: continueShoppingUrl }
          : {}),
      },
    });
  }
};
